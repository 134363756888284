@media (min-width: 750px) {
    .header__phonecall {
      display: none;
    }
    #menu {
      display: none;
    }
    #navcheck {
      display: none;
    }
    .visuallyhidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }
 
    .menu--active {
      color: #1937b9;
    }
    .main-nav {
      transform: none;
    }
    .stripe-nav {
      z-index: 4;
      background-color: #949494;
      height: 0.5px;
      width: 200px;
      margin: 0.2em 3em;
      color: #2f2f2f;
    }
    .nav--phone {
      padding-left: 2.6em;
    }
    .header__phonecall {
      position: fixed;
      top: 0.75em;
      left: 1.5em;
      z-index: 3;
      width: 2em;
      cursor: pointer;
    }
    .header__photo {
      z-index: 10000;
      padding-left: 30px;
      padding-right: 10px;
      box-sizing: border-box;
      max-width: 100%;
      margin-top: auto;
      margin-bottom: auto;
      grid-area: 0.125;
      transition: 1s;
      cursor: pointer;
    }
    .media_block {
      display: none;
    }
    .media_block--nav {
      padding-left: 2em;
      padding-top: 2em;
    }
    .submenu_button--nav {
      cursor: pointer;
      transition: 0.5s;
      padding-left: 10px;
      padding-right: 10px;
    }
    .submenu_button--nav img {
      width: 30px;
    }
    input {
      position: fixed;
      opacity: 0;
    }
    label {
      position: absolute;
      margin: 0;
      padding: 0;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
    }
    label::before {
      position: fixed;
      top: 58px;
      left: 0;
      right: 0;
      bottom: 0;
      height: 80em;
      background-color: rgba(0, 0, 0, 0.75);
      content: '';
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    }
    label .burger {
      position: fixed;
      top: 1em;
      right: 1.5em;
      z-index: 3;
      width: 1.5em;
      height: 1.5em;
      margin: 0;
      padding: 0;
      transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    }
    label .burger::before, label .burger .bar, label .burger::after {
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      height: 12%;
      background: #2f2f2f;
      content: '';
      transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    }
    label .burger .bar {
      top: 44%;
    }
    label .burger::before {
      top: 0;
      transform-origin: top left;
    }
    label .burger::after {
      bottom: 0;
      transform-origin: bottom left;
    }
    input:focus + label .burger, label:hover .burger {
      opacity: 0.75;
    }
    nav {
      top: 57px;
      position: fixed;
      padding-top: 3em;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      display: flex;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 1s linear;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
      transform: translate3d(0, 0, 0);
      /* material acceleration */
      /* transform: translateX(100%);*/
      will-change: transform;
      transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    }
    nav::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      width: 25em;
      background: white;
      content: '';
      transform: skewX(15deg) translateX(-100%);
      transform-origin: bottom left;
      will-change: transform;
      transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    }
    nav a {
      margin: 0.3em 0;
      padding: 0.2em 2em;
      font-size: 1.5em;
      color: #2f2f2f;
      text-decoration: none;
      font-weight: 500;
      transform: translateX(-100%);
      transition: color 0.15s, transform 0.5s;
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }
    nav a:nth-child(1) {
      transition-delay: 0s, 100ms;
    }
    nav a:nth-child(2) {
      transition-delay: 0s, 150ms;
    }
    nav a:nth-child(3) {
      transition-delay: 0s, 200ms;
    }
    nav a:nth-child(4) {
      transition-delay: 0s, 250ms;
    }
    nav a:nth-child(5) {
      transition-delay: 0s, 300ms;
    }
    nav a:nth-child(6) {
      transition-delay: 0s, 350ms;
    }
    nav a:hover, nav a:focus {
      color: black;
    }
    nav .media_block--nav {
      margin: 0.3em 0;
      padding: 0.1em 0.3em;
      font-size: 1.5em;
      color: #2f2f2f;
      text-decoration: none;
      font-weight: 500;
      transform: translateX(-100%);
      transition: color 0.15s, transform 0.5s;
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }
    nav .adress--nav {
      margin: 0.5em 0;
      padding: 0.2em 3.5em;
      font-size: 1.5em;
      color: #2f2f2f;
      text-decoration: none;
      font-weight: 500;
      transform: translateX(-100%);
      transition: color 0.15s, transform 0.5s;
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }
    main {
      overflow: hidden;
    }
    main .content {
      transform: translate3d(0, 0, 0);
      /* material acceleration */
      will-change: transform, filter;
      transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    }
    [id="navcheck"]:checked + label::before {
      opacity: 1;
      pointer-events: auto;
    }
    [id="navcheck"]:checked + label .burger::before, [id="navcheck"]:checked + label .burger::after {
      width: 141.42%;
    }
    [id="navcheck"]:checked + label .burger::before {
      transform: rotate(45deg) translateY(-50%);
    }
    [id="navcheck"]:checked + label .burger::after {
      transform: rotate(-45deg) translateY(50%);
    }
    [id="navcheck"]:checked + label .burger .bar {
      transform: scale(0.1);
    }
    [id="navcheck"]:checked ~ nav {
      visibility: visible;
      opacity: 1;
    }
    [id="navcheck"]:checked ~ nav::before {
      transform: skewX(15deg) translateX(0);
      visibility: visible;
      opacity: 1;
    }
    [id="navcheck"]:checked ~ nav a {
      transform: translateX(0);
    }
    [id="navcheck"]:checked ~ nav .media_block--nav {
      transform: translateX(0);
    }
    [id="navcheck"]:checked ~ nav .adress--nav {
      transform: translateX(0);
      /* helper */
    }
    .visuallyhidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  
  @media only screen and (max-width: 700px) and (min-width: 40em) {
    nav::before {
      width: 40em;
    }}
 
  